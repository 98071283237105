import { mapState } from "vuex";

export default {
  computed: {
    ...mapState("language", ["selectedLanguage"])
  },
  mounted() {
    this.$nextTick(() => {
      if (!this.selectedLanguage) {
        this.$router.push({ name: "r_translations" });
      }
    });
  }
};
